import { find, forEach } from 'lodash';

export default class DataManager {
    constructor() {
        this.dataSources = {};
        this.requests = {};
    }

    registerDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            source,
            parameters,
            transformations
        } */
        const id = properties.dataSourceId;
        if (!Object.prototype.hasOwnProperty.call(this.dataSources, id)) {
            let { source } = properties;
            if (typeof source === 'undefined') {
                // we use the arrow syntax here to avoid having to bind the fetch function to window.mwc.dataAccess
                // (using bind makes it difficult to override if you want to use your own application's fetch method)
                source = options => {
                    return window.mwc.dataAccess.fetch(options).then(response => {
                        return response.text().then(text => {
                            let data = text;
                            try {
                                // catch exceptions from parsing the response text as JSON
                                data = JSON.parse(text);
                            } catch (exception) {
                                window.mwc.logger.warn(null, exception.message, exception.stack);
                            }
                            // returns JSON if available, text if not
                            return response.ok ? data : Promise.reject(data);
                        });
                    });
                };
            }
            this.dataSources[id] = {
                source,
                parameters: properties.parameters,
                transformations: properties.transformations,
                subscribers: [],
            };
        }
    }

    updateDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            parameters
        } */
        const id = properties.dataSourceId;
        const dataSource = this.dataSources[id];
        dataSource.parameters = properties.parameters;

        this.fetchData(dataSource, id);
    }

    fetchData(dataSource, dataSourceId) {
        let transformedParameters = dataSource.parameters;
        if (dataSource.transformations && dataSource.transformations.length > 0) {
            transformedParameters = DataManager.transform(dataSource.transformations, {}, dataSource.parameters);
        }

        const request = dataSource.source(transformedParameters);
        const requestAborted = 'request aborted';

        if (dataSourceId) {
            if (this.requests[dataSourceId]) {
                // Abort any pending requests for this data source
                const pendingRequest = this.requests[dataSourceId];
                pendingRequest.requestStatus = requestAborted;
            }
            this.requests[dataSourceId] = request;
        }

        request
            .then(data => {
                if (request.requestStatus === requestAborted) {
                    throw new Error(requestAborted);
                }

                if (this.requests[dataSourceId]) {
                    delete this.requests[dataSourceId];
                }
                dataSource.data = data;
                dataSource.fetchInProgress = false;
                DataManager.setSubscriberData(dataSource);
            })
            .catch(err => {
                dataSource.fetchInProgress = false;
                // Only log an error if we didn't abort the request ourselves
                if (err.message !== requestAborted) {
                    DataManager.callSubscriberErrorFunctions(dataSource, err);
                    window.mwc.logger.error(null, err);
                }
            });
    }

    subscribe(properties) {
        /*
        properties: {
            mwcId,
            element,
            modelName,
            dataSourceId,
            transformationPipeline,
            successFunction
            errorFunction
        } */
        const dataSource = this.dataSources[properties.dataSourceId];

        const subscriptionExists = find(dataSource.subscribers, { mwcId: properties.mwcId });
        if (!subscriptionExists) {
            dataSource.subscribers.push({
                mwcId: properties.mwcId,
                element: properties.element,
                modelName: properties.modelName,
                transformationPipeline: properties.transformationPipeline,
                successFunction: properties.successFunction,
                errorFunction: properties.errorFunction,
            });
        } else if (subscriptionExists.element !== properties.element) {
            // Make sure the element is pointing to the latest reference
            subscriptionExists.element = properties.element;
        }

        if (!dataSource.data && !dataSource.fetchInProgress) {
            dataSource.fetchInProgress = true;
            this.fetchData(dataSource, properties.dataSourceId);
        } else if (dataSource.data) {
            DataManager.setSubscriberData(dataSource);
        }
    }

    subscribeComponent(mwcId, dataSourcesSettings, element) {
        forEach(dataSourcesSettings, dataSourceSetting => {
            if (dataSourceSetting && dataSourceSetting.dataSourceId) {
                const subscribePropertyObject = {
                    mwcId,
                    element,
                    modelName: dataSourceSetting.modelName,
                    dataSourceId: dataSourceSetting.dataSourceId,
                    transformationPipeline: dataSourceSetting.transformations,
                    successFunction: dataSourceSetting.successFunction,
                    errorFunction: dataSourceSetting.errorFunction,
                };
                this.subscribe(subscribePropertyObject);
            }
        });
    }

    static setSubscriberData(dataSource) {
        dataSource.subscribers.forEach(subscriber => {
            let modelWasSet = false;
            const subscriberData = DataManager.transform(
                subscriber.transformationPipeline || [],
                dataSource.data,
                dataSource.parameters
            );
            if (subscriber.element) {
                subscriber.element[subscriber.modelName] = subscriberData;
                modelWasSet = true;
            }
            if (subscriber.successFunction) {
                subscriber.successFunction({
                    modelWasSet,
                    modelName: subscriber.modelName,
                    modelData: subscriberData,
                });
            }
        });
    }

    static callSubscriberErrorFunctions(dataSource, err) {
        dataSource.subscribers.forEach(subscriber => {
            if (subscriber.errorFunction) {
                subscriber.errorFunction(err);
            }
        });
    }

    static transform(transformationPipeline, data, params) {
        transformationPipeline.forEach(transformation => {
            data = transformation(data, params);
        });
        return data;
    }
}
