import { get, set } from 'lodash';
import Configuration from './configuration/configuration';
import DataManager from './data-manager/data-manager';
import Formatter from './formatter/formatter';
import Labeller from './labeller/labeller';
import Logger from './logger/logger';
import RequestController from './data-access/request-controller';
import StorageHelper from './storage-helper/storage-helper';

export default {
    initializeMwc() {
        if (!get(window, 'mwc.configuration')) {
            set(window, 'mwc.configuration', new Configuration());
        }

        if (!get(window, 'mwc.logger')) {
            set(window, 'mwc.logger', new Logger());
        }

        if (!get(window, 'mwc.Formatter')) {
            set(window, 'mwc.Formatter', Formatter);
        }

        if (!get(window, 'mwc.Labeller')) {
            set(window, 'mwc.Labeller', Labeller);
        }

        if (!get(window, 'mwc.dataAccess')) {
            set(window, 'mwc.dataAccess', new RequestController());
        }

        if (!get(window, 'mwc.dataManager')) {
            set(window, 'mwc.dataManager', new DataManager());
        }

        if (!get(window, 'mwc.storageHelper')) {
            set(window, 'mwc.storageHelper', new StorageHelper());
        }
    },
};
