import { assign, omit, isUndefined, isNull, isFinite, isDate, cloneDeep } from 'lodash';
import baseFormats from './formats.json';

function formatIndicator(originalValue, formattedValue, mode) {
    const INDICATOR_PARENTHESES = 'parentheses';
    const INDICATOR_ABSOLUTE = 'absolute';
    const INDICATOR_HYPHEN = 'hyphen';

    let indicatorValue = formattedValue;

    if (originalValue < 0) {
        switch (mode) {
            case INDICATOR_PARENTHESES:
                indicatorValue = `(${formattedValue.replace('-', '')})`;
                break;
            case INDICATOR_ABSOLUTE:
                indicatorValue = formattedValue.replace('-', '');
                break;
            case INDICATOR_HYPHEN:
                if (!formattedValue.startsWith(`-`)) {
                    indicatorValue = `-${formattedValue}`;
                }
                break;
            default:
                // Replace hyphen with minus sign (u2212)
                indicatorValue = formattedValue.replace(`-`, `\u2212`);
                break;
        }
    }
    return indicatorValue;
}

function round(n, i) {
    return +`${Math.round(`${n}e+${i}`)}e-${i}`;
}

function checkIfRoundedValueIsZero(value, maximumFractionDigits) {
    return Math.abs(round(value, maximumFractionDigits)) === 0;
}

export default class Formatter {
    constructor(languageId, overrideFormats, currencyCode) {
        this.languageId = languageId || 'en-US';
        this.currencyCode = currencyCode || 'USD';
        this.formats = assign({}, baseFormats, overrideFormats);
        this.specialCurrencies = {
            GBX: {
                symbol: 'p',
                name: 'pence',
            },
        };
        this.simpleNumberFormatter = value => value.toFixed(0);
        this.simpleDateFormatter = (value, locale) => value.toLocaleDateString(locale);
    }

    format(value, type, opts) {
        // If value is not set, return nullString
        if (isUndefined(value) || isNull(value)) {
            return this.formats.nullString;
        }

        const derivedFormat = this.constructor.getDerivedFormat(this.formats, value, type);

        // Select appropriate opts based on input
        const defaultOpts = cloneDeep(this.formats[type] || this.formats[derivedFormat]);
        if (type === 'date') {
            opts = opts || defaultOpts || {}; // override opts will be used as they are
        } else {
            opts = assign(defaultOpts, opts || {}); // override opts will be merged with default opts
        }
        opts = omit(opts, 'dataType');

        let formattedValue;
        switch (derivedFormat) {
            case 'number': {
                const specialCurrency = this.specialCurrencies[opts.currency];
                if (checkIfRoundedValueIsZero(value, opts.maximumFractionDigits)) {
                    value = 0;
                }
                if (opts.style === 'currency' && specialCurrency) {
                    formattedValue = value;
                    switch (opts.currencyDisplay) {
                        case 'name':
                            opts.suffix = ` ${specialCurrency.name}`;
                            break;
                        case 'code':
                            opts.suffix = ` ${opts.currency}`;
                            break;
                        default:
                            opts.suffix = specialCurrency.symbol;
                    }
                } else if (window.Intl) {
                    formattedValue = window.Intl.NumberFormat(this.languageId, opts).format(value);
                } else {
                    formattedValue = this.simpleNumberFormatter(value);
                }
                // do extra indicator formatting if user specified the indicator mode
                formattedValue = formatIndicator(value, formattedValue, opts.indicatorMode);
                break;
            }
            case 'date': {
                if (window.Intl) {
                    formattedValue = window.Intl.DateTimeFormat(this.languageId, opts).format(value);
                } else {
                    formattedValue = this.simpleDateFormatter(value, this.languageId);
                }
                break;
            }
            case 'abbreviation': {
                if (window.Intl) {
                    const cloneOpts = cloneDeep(opts);
                    if (opts.abbreviate.mode === 'auto') {
                        switch (true) {
                            case value >= 10 ** 12:
                                cloneOpts.abbreviate.mode = 'trillions';
                                cloneOpts.abbreviate.modeValue = 10 ** 12;
                                break;
                            case value >= 10 ** 9:
                                cloneOpts.abbreviate.mode = 'billions';
                                cloneOpts.abbreviate.modeValue = 10 ** 9;
                                break;
                            case value >= 10 ** 6:
                                cloneOpts.abbreviate.mode = 'millions';
                                cloneOpts.abbreviate.modeValue = 10 ** 6;
                                break;
                            default:
                                cloneOpts.abbreviate.mode = 'thousands';
                                cloneOpts.abbreviate.modeValue = 10 ** 3;
                        }
                    }
                    formattedValue = window.Intl.NumberFormat(this.languageId, cloneOpts).format(
                        value / cloneOpts.abbreviate.modeValue
                    );
                    if (!/[1-9]/.test(formattedValue) && !cloneOpts.abbreviate.allowZero) {
                        // If formatted value is 0.00 or 0.000 etc... do not add the abbreviation letter and return the original value
                        formattedValue = value;
                    } else {
                        if (
                            checkIfRoundedValueIsZero(
                                value / cloneOpts.abbreviate.modeValue,
                                opts.maximumFractionDigits
                            )
                        ) {
                            formattedValue = formattedValue.replace('-', '');
                        }
                        formattedValue += opts.abbreviate.abbreviations[cloneOpts.abbreviate.mode];
                    }
                } else {
                    formattedValue =
                        this.simpleNumberFormatter(value / opts.abbreviate.modeValue) +
                        opts.abbreviate.abbreviations[opts.abbreviate.mode];
                }
                // do extra indicator formatting if user specified the indicator mode
                formattedValue = formatIndicator(value, formattedValue, opts.indicatorMode);
                break;
            }
            case 'text':
            default:
                formattedValue = value;
        }

        if (opts.prefix || opts.suffix) {
            formattedValue = `${opts.prefix || ''}${formattedValue}${opts.suffix || ''}`;
        }

        return formattedValue;
    }

    static getDerivedFormat(formats, value, requestedFormat) {
        let formatName = requestedFormat;

        // If 'any' format is passed in, select a reasonable format
        if (requestedFormat === 'any') {
            if (isFinite(value)) {
                formatName = 'number';
            } else if (isDate(value)) {
                formatName = 'date';
            }
        }

        // If we have a supported format, make ensure it has a dataType
        const format = formats[formatName];
        if (format) {
            if (!format.dataType) {
                throw new Error('Format missing dataType.', formatName);
            }
            formatName = format.dataType;
        }

        return formatName;
    }

    number(value, format, opts) {
        return this.format(value, format || 'number', opts);
    }

    date(value, format) {
        return this.format(value, format || 'date');
    }

    currency(value, code) {
        const opts = {
            style: 'currency',
            currency: code || this.currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return this.format(value, 'number', opts);
    }

    currencySymbolOnly(code) {
        const opts = {
            style: 'currency',
            currency: code || this.currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currencyDisplay: 'symbol',
        };
        return this.format(1, 'number', opts)
            .replace('1', '')
            .trim();
    }
}
